import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.eligibles.productList.get.replace(':saleId', `${saleId}`))
}

export async function update(saleId, data) {
  return EffectUtility.put(environment.managerApi.endpoints.sale.eligibles.productList.update.replace(':saleId', `${saleId}`), data)
}
