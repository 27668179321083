import {
  BookFilled,
  CreditCardOutlined,
  FormOutlined,
  WalletOutlined,
  NotificationOutlined,
  FolderOpenOutlined,
  ProfileOutlined,
  QrcodeOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  BarcodeOutlined,
  ClusterOutlined,
  DollarCircleOutlined,
  ScheduleOutlined,
  UserSwitchOutlined,
  TrademarkOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'

import {
  WalletGuard,
  NotificationGuard,
  PartnerGuard,
  ParticipantSegmentGuard,
  PromotionalCodeGuard,
  PinCodeGuard,
  BudgetGuard,
  CreditConsultingGuard,
  TransactionAuditGuard,
  TransactionConsultingGuard,
} from '@aberto/react-common'

export default function baseEnv() {
  return {
    route: {
      baseRoute: '/',
    },
    mfe: [
      {
        name: 'Consulta de transação',
        basename: 'transaction-consulting',
        urlDev: 'http://localhost:8081/Aberto-transaction-consulting.js',
        url: 'https://microfrontendhml.blob.core.windows.net/transaction-consulting/Aberto-transaction-consulting.js',
        sideBar: {
          guard: TransactionConsultingGuard,
          icon: BookFilled,
          name: 'Consulta de transação',
          path: '/transaction-consulting',
        },
      },
      {
        name: 'Auditoria de transação',
        basename: 'transaction-audit',
        urlDev: 'http://localhost:8082/Aberto-transaction-audit.js',
        url: 'https://microfrontendhml.blob.core.windows.net/transaction-audit/Aberto-transaction-audit.js',
        sideBar: {
          guard: TransactionAuditGuard,
          icon: FormOutlined,
          name: 'Auditoria de transação',
          path: '/transaction-audit',
        },
      },
      {
        name: 'Consulta de créditos',
        basename: 'credit-consulting',
        urlDev: 'http://localhost:8083/Aberto-credit-consulting.js',
        url: 'https://microfrontendhml.blob.core.windows.net/credit-consulting/Aberto-credit-consulting.js',
        sideBar: {
          guard: CreditConsultingGuard,
          icon: CreditCardOutlined,
          name: 'Consulta de créditos',
          path: '/credit-consulting',
        },
      },

      {
        name: 'Budget',
        basename: 'budget',
        urlDev: 'http://localhost:8084/Aberto-budget.js',
        url: 'https://microfrontendhml.blob.core.windows.net/budget/Aberto-budget.js',
        sideBar: {
          guard: BudgetGuard,
          icon: WalletOutlined,
          name: 'Budget',
          path: '/budget',
        },
      },
      {
        name: 'Pin Code',
        basename: 'pin-code',
        urlDev: 'http://localhost:8085/Tradeback-pin-code.js',
        url: 'https://microfrontendhml.blob.core.windows.net/pin-code/Tradeback-pin-code.js',
        sideBar: {
          guard: PinCodeGuard,
          icon: QrcodeOutlined,
          name: 'Pin Code',
          path: '/pin-code',
        },
      },
      {
        name: 'Hub de Notificação',
        basename: 'notification-hub',
        urlDev: 'http://localhost:8086/Tradeback-notification-hub.js',
        url: 'https://microfrontendhml.blob.core.windows.net/notification-hub/Tradeback-notification-hub.js',
        sideBar: {
          guard: NotificationGuard,
          icon: NotificationOutlined,
          name: 'Hub de Notificação',
          path: '/notification-hub',
        },
      },
      {
        name: 'Wallet',
        basename: 'wallet',
        urlDev: 'http://localhost:8087/Tradeback-wallet.js',
        url: 'https://microfrontendhml.blob.core.windows.net/wallet/Tradeback-wallet.js',
        sideBar: {
          guard: WalletGuard,
          icon: FolderOpenOutlined,
          name: 'Wallet',
          path: '/wallet',
        },
      },
      // {
      //   name: 'Cupom Fiscal',
      //   basename: 'receipt',
      //   urlDev: 'http://localhost:8088/Tradeback-receipt.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/receipt/Tradeback-receipt.js',
      //   sideBar: {
      //     icon: ProfileOutlined,
      //     name: 'Cupom Fiscal',
      //     path: '/receipt',
      //   },
      // },
      {
        name: 'Participante',
        basename: 'participant',
        urlDev: 'http://localhost:8089/Tradeback-participant.js',
        url: 'https://microfrontendhml.blob.core.windows.net/participant/Tradeback-participant.js',
        sideBar: {
          guard: ParticipantSegmentGuard,
          icon: UserOutlined,
          name: 'Participante',
          path: '/participant',
        },
      },
      {
        name: 'Código Promocional',
        basename: 'promotional-code',
        urlDev: 'http://localhost:8090/Tradeback-promotional-code.js',
        url: 'https://microfrontendhml.blob.core.windows.net/promotional-code/Tradeback-promotional-code.js',
        sideBar: {
          guard: PromotionalCodeGuard,
          icon: BarcodeOutlined,
          name: 'Código Promocional',
          path: '/promotional-code',
        },
      },
      // {
      //   name: 'Campanha',
      //   basename: 'campaign',
      //   urlDev: 'http://localhost:8091/Tradeback-campaign.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/campaign/Tradeback-campaign.js',
      //   sideBar: {
      //     icon: ClusterOutlined,
      //     name: 'Campanha',
      //     path: '/campaign',
      //   },
      // },
      // {
      //   name: 'Produto',
      //   basename: 'product',
      //   urlDev: 'http://localhost:8092/Tradeback-product.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/product/Tradeback-product.js',
      //   sideBar: {
      //     icon: ShoppingCartOutlined,
      //     name: 'Produto',
      //     path: '/product',
      //   },
      // },
      // {
      //   name: 'Ofertas',
      //   basename: 'sale',
      //   urlDev: 'http://localhost:8093/Tradeback-sale.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/sale/Tradeback-sale.js',
      //   sideBar: {
      //     icon: DollarCircleOutlined,
      //     name: 'Ofertas',
      //     path: '/sale',
      //   },
      // },
      // {
      //   name: 'Horário da Sorte',
      //   basename: 'luck-times',
      //   url: 'http://localhost:8094/Tradeback-luck-times.js',
      //   urlHml: 'https://microfrontendhml.blob.core.windows.net/luck-times/Tradeback-luck-times.js',
      //   sideBar: {
      //     icon: ScheduleOutlined,
      //     name: 'Horário da Sorte',
      //     path: '/luck-times',
      //   name: 'Bin de cartão',
      //   basename: 'card-bin',
      //   urlDev: 'http://localhost:8095/Tradeback-card-bin.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/card-bin/Tradeback-card-bin.js',
      //   sideBar: {
      //     icon: CreditCardOutlined,
      //     name: 'Bin de cartão',
      //     path: '/card-bin',
      //   },
      // },
      // {
      //   name: 'Limite de Participação',
      //   basename: 'participation-limit',
      //   urlDev: 'http://localhost:8096/Tradeback-participation-limit.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/participation-limit/Tradeback-participation-limit.js',
      //   sideBar: {
      //     icon: UserSwitchOutlined,
      //     name: 'Limite de Participação',
      //     path: '/participation-limit',
      //   },
      // },
      // {
      //   name: 'Empresas',
      //   basename: 'company',
      //   urlDev: 'http://localhost:8097/Tradeback-company.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/company/Tradeback-company.js',
      //   sideBar: {
      //     icon: TrademarkOutlined,
      //     name: 'Empresas',
      //     path: '/company',
      //   },
      // },
      // {
      //   name: 'Palavras Proibidas',
      //   basename: 'forbidden-words',
      //   urlDev: 'http://localhost:8098/Tradeback-forbidden-words.js',
      //   url: 'https://microfrontendhml.blob.core.windows.net/forbidden-words/Tradeback-forbidden-words.js',
      //   sideBar: {
      //     icon: FileTextOutlined,
      //     name: 'Palavras Proibidas',
      //     path: '/forbidden-words',
      //   },
      // },
      {
        name: 'Parceiros',
        basename: 'partner',
        urlDev: 'http://localhost:8099/Tradeback-partner.js',
        url: 'https://microfrontendhml.blob.core.windows.net/partner/Tradeback-partner.js',
        sideBar: {
          guard: PartnerGuard,
          icon: TeamOutlined,
          name: 'Parceiros',
          path: '/partner',
        },
      },
    ],

    managerApi: {
      baseUrlLocal: 'https://localhost:44363',
      baseUrl: 'https://api-hml.aberto.com.br/manager/v1',
      baseUrlDev: 'https://abertomanagerdevapi.azurewebsites.net',
      subscriptionKey: '0191041c191d4415acdddbfd6d572eaa',
      subscriptionKeyDev: 'f572f82c6da74c888ee78c3c6aa2e2e2',
      authentication: {
        clientId: '5a5b7996-168a-4860-bf20-67f591e42059',
        authorityDomain: 'abertomanagerhml.b2clogin.com',
        names: {
          signUpSignIn: 'B2C_1A_SIGNIN',
          forgotPassword: 'B2C_1A_PASSWORDRESET',
          editProfile: 'b2c_1a_profileedit',
        },
        authorities: {
          signUpSignIn: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_SIGNIN' },
          forgotPassword: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_PASSWORDRESET' },
          editProfile: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_PROFILEEDIT' },
        },
        scopes: {
          login: ['https://abertomanagerhml.onmicrosoft.com/manager-api/access_as_user'],
          token: ['https://abertomanagerhml.onmicrosoft.com/manager-api/access_as_user'],
        },
      },
      endpoints: {
        authorization: {
          policy: {
            roles: '/authorization/policy/roles',
          },
        },
        address: {
          get: '/address/search/zip-code/:zipCode',
        },
        notification: {
          getTemplate: '/notification/template/:notificationType/identification-info',
          getProvider: '/notification/provider/:notificationType/identification-info',
          getTemplateById: '/notification/template/:templateId',
        },
        transactionDelivery: {
          identificationInfo: '/transaction-delivery/configuration/identification-info',
        },
        sale: {
          search: '/sale/search',
          identificationInfo: '/sale/identification-info',
          basicInfo: '/sale/:saleId/basic-info',
          basicInfoList: '/sale/basic-info/filter-sales-ids',
          activateCondition: '/sale/:saleId/activation-condition',
          benefit: '/sale/:saleId/benefit',
          budget: {
            identificationInfo: '/budget/identification-info',
          },
          advertisement: {
            identificationInfo: '/advertisement-category/identification-info',
            get: '/Sale/:saleId/advertisement',
            update: '/Sale/:saleId/advertisement/basic-info',
            multipleUpload: '/Sale/:saleId/advertisement/images/multiple',
          },
          delimitations: {
            get: '/sale/:saleId/delimitations',
            patch: '/sale/:saleId/delimitations',
          },
          store: {
            search: '/sale/:saleId/store/search',
            add: '/sale/:saleId/stores',
            vinculateFromFilter: '/sale/:saleId/chain/:chainId/store/vinculate-from-filter',
            delete: '/sale/:saleId/stores',
          },
          eligibles: {
            participants: {
              get: '/sale/:saleId/eligibles/participant',
              update: '/sale/:saleId/eligibles/participant',
            },
            pinCode: {
              get: '/sale/:saleId/eligibles/pin-code',
              update: '/sale/:saleId/eligibles/pin-code',
            },
            products: {
              productSearch: '/product/search',
              search: '/sale/:saleId/eligibles/product/search',
              getAll: '/sale/:saleId/eligibles/product',
              update: '/sale/:saleId/eligibles/product',
              add: '/Sale/:saleId/eligibles/product/add-eligibles',
              remove: '/Sale/:saleId/eligibles/product/remove-eligibles',
              vinculateByFilter: '/Sale/:saleId/eligibles/product/vinculate-by-filter',
            },
            productList: {
              get: '/sale/:saleId/eligibles/product-list',
              update: '/sale/:saleId/eligibles/product-list',
            },
            attributes: {
              get: '/Sale/:saleId/eligibles/attributes',
              update: '/Sale/:saleId/eligibles/attributes',
            },
            payment: {
              update: '/sale/:saleId/eligibles/payment',
              get: '/sale/:saleId/eligibles/payment',
            },
            participationAuthorization: {
              get: '/sale/:saleId/eligibles/participation-authorization',
              update: '/sale/:saleId/eligibles/participation-authorization',
            },
          },
          nonEligibles: {
            products: {
              search: '/sale/:saleId/eligibles/product/non-eligible/search',
              add: '/Sale/:saleId/eligibles/product/add-non-eligibles',
              remove: '/Sale/:saleId/eligibles/product/remove-non-eligibles',
            },
          },
          feedbackMessages: {
            get: '/sale/:saleId/feedback-messages',
            update: '/sale/:saleId/feedback-messages',
          },
          notification: {
            get: '/sale/:saleId/notification',
            update: '/sale/:saleId/notification',
          },
          summary: '/sale/:saleId/summary',
          changeStatus: '/sale/:saleId/change-status/:status',
          create: '/sale',
        },
        luckTimes: {
          new: '/luck-times/base',
          identificationInfo: '/luck-times/base/identification-info',
          addTimes: '/luck-times/base/:baseId/add-times',
        },
        luckyNumbers: {
          identificationInfo: '/lucky-numbers/base/identification-info',
        },
        promotionalCode: {
          new: '/promotional-code-base',
          get: '/promotional-code-base',
          getDetails: '/promotional-code-base/:baseId/details',
          update: '/promotional-code-base/:baseId',
          updateStatus: '/promotional-code-base/:baseId/update-status/:statusId',
          identificationInfo: '/promotional-code-base/identification-info',
          import: '/promotional-code-base/:baseId/import',
        },
        campaign: {
          identificationInfo: '/campaign/identification-info',
          create: '/campaign',
          update: '/campaign/:campaignId',
          getById: '/campaign/:campaignId',
          search: '/campaign/search',
        },
        product: {
          create: '/product',
          createPublic: '/product/public',
          update: '/product/:productId',
          getById: '/product/:productId',
          search: '/product/search',
          category: {
            searchByName: '/product/category/name/:name',
          },
          import: {
            initializeImport: '/product/import',
            getById: '/product/import/:id',
            search: '/product/import/search',
          },
        },
        productList: {
          identificationInfo: '/product-list/identification-info',
          search: '/product-list/search',
          add: '/product-list',
          update: '/product-list/:id',
          remove: '/product-list/:id',
          import: '/product-list/import',
        },
        participationAuthorization: {
          base: {
            search: '/participation-authorization/base/search',
            add: '/participation-authorization/base',
            update: '/participation-authorization/base/:authorizationBaseId',
            identificationInfo: '/participation-authorization/base/identification-info',
          },
          search: '/participation-authorization/search',
        },
        partner: {
          identificationInfo: '/partner/identification-info',
        },
        company: {
          chain: {
            identificationInfo: '/company/chain/identification-info/search',
          },
          paying: {
            identificationInfo: '/company/paying/identification-info/search',
          },
          promoter: {
            identificationInfo: '/company/promoter/identification-info/search',
          },
          create: '/company',
          search: '/company/search',
          getById: '/company/:companyId',
          update: '/company/:companyId',
          changeStatus: '/company/:companyId/change-status/:status',
          updateImage: '/company/:companyId/images/:imageId',
          createImage: '/company/:companyId/images',
          deleteImage: '/company/:companyId/images/:imageId',
          import: '/company/import',
          importSearch: '/company/import/search',
          getImportById: '/company/import/:importId',
          report: '/company/report',
          category: {
            search: '/company-category/search',
            searchByName: '/company-category/by-name',
          },
          subCategory: {
            import: {
              search: '/company-category/:categoryId/sub-category/import/search',
              import: '/company-category/:categoryId/sub-category/import',
            },
            search: '/company-category/:categoryId/sub-category/search',
            getById: '/company-category/:categoryId/sub-category/:subcategoryId',
            create: '/company-category/:categoryId/sub-category',
            update: '/company-category/:categoryId/sub-category/:subcategoryId',
            disable: '/company-category/:categoryId/sub-category/:subcategoryId/disable',
            enable: '/company-category/:categoryId/sub-category/:subcategoryId/enable',
          },
        },
        participationLimit: {
          identificationInfo: '/participation-limit-group/identification-info',
          post: '/participation-limit-group',
          get: '/participation-limit-group/:userId',
          put: '/participation-limit-group/:userId',
        },
        participantSegment: {
          list: '/participant-segment/identification-info',
          search: '/participant-segment/search',
          get: '/participant-segment/:segmentId',
          create: '/participant-segment',
          update: '/participant-segment/:segmentId',
          import: '/participant-segment/:segmentId/import',
          importMerge: '/participant-segment/merge',
          importOverride: '/participant-segment/override',
          importSearch: 'participant-segment/import/search',
        },
        cardBinSegment: {
          search: '/card-bin-segment/search',
          create: '/card-bin-segment',
          update: '/card-bin-segment/:segmentId',
          getById: '/card-bin-segment/:segmentId',
          activate: '/card-bin-segment/:segmentId/activate',
          inactivate: '/card-bin-segment/:segmentId/disable',
          identificationInfo: '/card-bin-segment/identification-info',
          import: {
            import: '/card-bin-segment/:segmentId/import',
            search: '/card-bin-segment/import/search',
          },
        },
        wallet: {
          identificationInfo: '/wallet/identification-info',
        },
        pinCode: {
          identificationInfo: '/pin-code/base/identification-info',
        },
        forbiddenWords: {
          search: '/forbidden-word/search',
          enable: '/forbidden-word/:forbiddenWordId/enable',
          disable: '/forbidden-word/:forbiddenWordId/disable',
          edit: '/forbidden-word/:forbiddenWordId',
          create: '/forbidden-word',
          getById: '/forbidden-word/:forbiddenWordId',
          import: '/forbidden-word/import',
        },
        store: {
          search: '/store/search',
          create: '/store',
          edit: '/store/:storeId',
          getById: '/store/:storeId',
          enable: '/store/:storeId/activate',
          disable: '/store/:storeId/disable',
          import: '/store/:chainId/import',
          publish: '/Store/publish',
          getAllImport: '/store/:chainId/import/get-all',
          registerSoftwareExpress: '/store/:storeId/software-express/send',
        },
      },
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  }
}
