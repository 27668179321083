import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ProductListActions'

export const initialState = {
  identificationInfo: [
    {
      id: '',
      description: '',
    },
  ],
  currentProductList: {
    id: null,
    name: null,
    productChainId: null,
    productCodeType: null,
  },
  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      name: null,
      type: null,
      code: null,
      productChainId: null,
    },
  },
  searchResponse: {
    numberOfRows: 0,
    data: [],
  },
}

const productListReducer = baseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchResponse.data = payload.data
      draftState.searchResponse.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentProduct = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = payload
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.filter = initialState.search.filter
    })
  },
  [Actions.REQUEST_CREATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentProductList.id = payload
    })
  },
  [Actions.SET_CURRENT](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentProductList = payload
    })
  },
})

export default productListReducer
