import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleProductListActions'

export const initialState = {
  required: false,
  productListIds: [],
}

const saleProductListReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_ELIGIBLE_PRODUCT_LIST_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.required = payload.required
      draft.productListIds = payload.productListIds
    })
  },
})

export default saleProductListReducer
