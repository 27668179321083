import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleSummaryActions'

export const initialState = {
  response: {
    name: '',
    description: '',
    duration: {
      initialDateUTC: null,
      finalDateUTC: null,
      daysOfWeek: [],
      intervalsTimeZoneOffset: null,
      intervals: [],
    },
    campaign: {
      id: null,
      name: '',
    },
    promoter: {
      id: null,
      name: '',
    },
    payingCompany: {
      id: null,
      name: '',
    },
    chains: [],
    eligibles: {
      eligibleProduct: {
        allowAnyProduct: false,
        products: [],
      },
      eligiblePinCode: {
        required: false,
        baseIds: [],
      },
      eligibleParticipant: {
        required: false,
        segmentIds: [],
      },
      eligiblePayment: {
        cardBin: {
          required: false,
          segmentIds: [],
        },
        entryMode: {
          required: false,
        },
        type: {
          required: false,
        },
      },
    },
    activationCondition: {
      firstLevelCondition: {
        spentValue: {
          multipleActivation: false,
          value: 0,
        },
        availableWalletBalance: null,
      },
      secondLevelCondition: {
        spendingBehavior: {
          spentValueToBeRewarded: 0,
          countType: 0,
          spendingBehaviorSettingsId: 0,
        },
      },
      thirdLevelCondition: {
        luckTimes: {
          luckTimesBaseId: '',
        },
      },
    },
    benefit: {},
    status: 0,
    availableStatus: [],
    disabledStatusReason: null,
  },
}

const saleSummaryReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.response = payload
    })
  },
})

export default saleSummaryReducer
