import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ProductListEffects'

export const REQUEST_IDENTIFICATION_INFO = 'ProductListActions.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'ProductListActions.REQUEST_IDENTIFICATION_INFO_FINISHED'

export const REQUEST_GET_BY_ID = 'ProductListActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'ProductListActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'ProductListActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ProductListActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'ProductListActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'ProductListActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_SEARCH = 'ProductListActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ProductListActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ProductListActions.SET_FILTER'
export const SET_PAGINATION = 'ProductListActions.SET_PAGINATION'
export const CLEAR = 'ProductListActions.CLEAR'
export const CLEAR_FILTER = 'ProductListActions.CLEAR_FILTER'

export const REQUEST_IMPORT = 'ProductListImportActions.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'ProductListImportActions.REQUEST_IMPORT_FINISHED'

export const SET_CURRENT = 'ProductListImportActions.SET_CURRENT'

export function getProductListIdentificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.getIdentificationInfo)
  }
}

export function create(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          message: 'Produto criado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function update(currentProductListId, data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          message: 'Produto atualizado com sucesso',
          type: NotificationEnum.Success,
        },
      },
      currentProductListId,
      data
    )
  }
}

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().productList.search.filter,
      getState().productList.search.pagination
    )
  }
}

export const importProduct = (productListId, file) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      Effects.importProduct,
      {
        notification: {
          message: 'Importação iniciada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      productListId,
      file
    )
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)

export function setCurrent(item) {
  return ActionUtility.createAction(SET_CURRENT, item)
}
