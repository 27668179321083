/* eslint-disable react/display-name */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import {
  EditOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  WarningOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { Button, Table, Typography } from 'antd'
import { selectRequesting } from '../../../../selectors/requesting/RequestingSelector'
import { searchResult } from '../../../../selectors/sale/SaleSearchSelectors'
import * as Actions from '../../../../stores/sale/search/SaleSearchActions'
import { getStatusTag } from '../../../../utilities/TagUtility'
import CustomTooltip from '../../../components/custom-tooltip/CustomTooltip'
import CustomTooltipButton from '../../../components/custom-tooltip-button/CustomTooltipButton'
import { parse, isBefore } from 'date-fns';

const { Text } = Typography

export default function SaleSearchResultTable() {
  const dispatch = useDispatch()
  const isRequesting = useSelector(state => selectRequesting(state, [Actions.REQUEST_SEARCH]))
  const { pagination, data } = useSelector(searchResult)

  const goToBasicInfo = id => dispatch(push(`/sale/${id}/basic-info`))

  const statusTag = status => {
    switch (status) {
      case 1:
        return <CustomTooltip icon={<WarningOutlined />} color={'orange'} text={'Alterações Pendentes'} />
      case 2:
        return <CustomTooltip icon={<ClockCircleOutlined />} color={'lightblue'} text={'Aprovação Solicitada'} />
      case 3:
        return <CustomTooltip icon={<CloseCircleOutlined />} color={'red'} text={'Alterações Reprovadas'} />
      case 5:
        return <CustomTooltip icon={<CheckCircleOutlined />} color={'darkgreen'} text={'Ativa'} />
      case 6:
        return <CustomTooltip icon={<MinusCircleOutlined />} color={'gray'} text={'Inativa'} />
      case 8:
        return <CustomTooltip icon={<CheckOutlined />} color={'purple'} text={'Finalizada'} />
    }
  }

  const validityTag = sale => {
    const inativeSale = 6
    const finalDate = parse(sale.finalDate, "dd/MM/yyyy HH:mm", new Date());
    const isBeforeCurrentDate = isBefore(finalDate, new Date());

    if (isBeforeCurrentDate && sale.status == inativeSale)
      return <CustomTooltip icon={<MinusCircleOutlined />} color={'gray'} text={'Fora da vigência e inativa'} />

    if (sale.status == inativeSale)
      return <CustomTooltip icon={<MinusCircleOutlined />} color={'gray'} text={'Inativa'} />

    if (isBeforeCurrentDate)
      return <CustomTooltip icon={<CloseCircleOutlined />} color={'red'} text={'Fora da vigência'} />

    return <CustomTooltip icon={<CheckCircleOutlined />} color={'darkgreen'} text={'Vigente'} />
  }

  const renders = {
    name: (text, record) => (
      <Button id="linkTableSearch" type="link" onClick={() => goToBasicInfo(record.id)}>
        {text}
      </Button>
    ),
    campaign: text => <span>{text}</span>,
    status: (...args) => getStatusTag(args[1].status),
    actions: (text, record) => <CustomTooltipButton icon={<EditOutlined />} text="Editar" color="orange" onClick={() => goToBasicInfo(record.id)} />,
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Campanha',
      dataIndex: 'campaign',
    },
    {
      title: 'Promotor',
      dataIndex: 'promoter',
    },
    {
      title: 'Data Inicial',
      dataIndex: 'initialDate',
    },
    {
      title: 'Data Final',
      dataIndex: 'finalDate',
    },
    {
      title: 'Vigência',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
      render: (text, record) => validityTag(record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
      render: (text, record) => statusTag(record.status),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '80px',
      align: 'center',
      render: (a, b) => renders.actions(a, b, dispatch),
    },
  ]

  const handleTableChange = ({ current, pageSize } = pagination, filters, sorter) => {
    dispatch(Actions.setPagination({ pageSize, pageNumber: current }))
    dispatch(Actions.search())
  }

  return (
    <Table
      id="table"
      footer={() => <Text strong>{pagination.total} Oferta(s) Encontrada(s)</Text>}
      columns={columns}
      rowKey={record => record.id}
      dataSource={data}
      pagination={pagination}
      loading={isRequesting}
      onChange={handleTableChange}
    />
  )
}
