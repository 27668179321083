import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleProductListEffects'

export const REQUEST_GET_ELIGIBLE_PRODUCT_LIST = 'SaleProductList.REQUEST_GET_ELIGIBLE_PRODUCT_LIST'
export const REQUEST_GET_ELIGIBLE_PRODUCT_LIST_FINISHED = 'SaleProductList.REQUEST_GET_ELIGIBLE_PRODUCT_LIST_FINISHED'

export const REQUEST_UPDATE_ELIGIBLE_PRODUCT_LIST = 'SaleProductList.REQUEST_UPDATE_ELIGIBLE_PRODUCT_LIST'
export const REQUEST_UPDATE_ELIGIBLE_PRODUCT_LIST_FINISHED = 'SaleProductList.REQUEST_UPDATE_ELIGIBLE_PRODUCT_LIST_FINISHED'

export function getEligibleProductList(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_ELIGIBLE_PRODUCT_LIST, Effects.get, saleId)
  }
}

export function update(saleId, data) {
  return async (dispatch, getState) => {
    ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_ELIGIBLE_PRODUCT_LIST,
      Effects.update,
      {
        notification: {
          message: 'Lista de produtos atualizada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}
