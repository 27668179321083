import { produce } from 'immer'

import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleActivateConditionActions'

export const initialState = {
  firstLevelCondition: {
    spentValue: null,
    productQuantity: null,
    combo: null,
    availableWalletBalance: null,
  },
  secondLevelCondition: {
    visitBehavior: null,
    spendingBehavior: null,
  },
  thirdLevelCondition: {
    luckTimes: null,
    stamp: null,
  },
}

const saleActivateConditionReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draft => {
      Object.keys(payload).forEach(level => {
        Object.keys(payload[level]).forEach(condition => {
          draft[level][condition] = payload[level][condition]
        })
      })
    })
  },
  [Actions.SET_CONDITION](state, { payload: { level, type, value } }) {
    return produce(state, draft => {
      draft[level][type] = value
    })
  },
  [Actions.REMOVE_CONDITION](state, { payload: { level, type } }) {
    return produce(state, draft => {
      draft[level][type] = null
    })
  },
  [Actions.CLEAN](state, { payload }) {
    return initialState
  },
})

export default saleActivateConditionReducer
