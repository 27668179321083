import {
  BookFilled,
  FormOutlined,
  CreditCardOutlined,
  WalletOutlined,
  QrcodeOutlined,
  NotificationOutlined,
  FolderOpenOutlined,
  ProfileOutlined,
  TeamOutlined,
  BarcodeOutlined,
  ClusterOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  ScheduleOutlined,
  UserSwitchOutlined,
  TrademarkOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'

const externalConfig = {
  mfe: [
    {
      name: 'Consulta de transação',
      basename: 'transaction-consulting',
      url: 'https://microfrontendhml.blob.core.windows.net/transaction-consulting/Aberto-transaction-consulting.js',
      sideBar: {
        icon: BookFilled,
        name: 'Consulta de transação',
        path: '/transaction-consulting',
      },
    },
    {
      name: 'Auditoria de transação',
      basename: 'transaction-audit',
      url: 'https://microfrontendhml.blob.core.windows.net/transaction-audit/Aberto-transaction-audit.js',
      sideBar: {
        icon: FormOutlined,
        name: 'Auditoria de transação',
        path: '/transaction-audit',
      },
    },
    {
      name: 'Consulta de créditos',
      basename: 'credit-consulting',
      url: 'https://microfrontendhml.blob.core.windows.net/credit-consulting/Aberto-credit-consulting.js',
      sideBar: {
        icon: CreditCardOutlined,
        name: 'Consulta de créditos',
        path: '/credit-consulting',
      },
    },
    {
      name: 'Budget',
      basename: 'budget',
      url: 'https://microfrontendhml.blob.core.windows.net/budget/Aberto-budget.js',
      sideBar: {
        icon: WalletOutlined,
        name: 'Budget',
        path: '/budget',
      },
    },
    {
      name: 'Pin Code',
      basename: 'pin-code',
      url: 'https://microfrontendhml.blob.core.windows.net/pin-code/Tradeback-pin-code.js',
      sideBar: {
        icon: QrcodeOutlined,
        name: 'Pin Code',
        path: '/pin-code',
      },
    },
    {
      name: 'Hub de Notificação',
      basename: 'notification-hub',
      url: 'https://microfrontendhml.blob.core.windows.net/notification-hub/Tradeback-notification-hub.js',
      sideBar: {
        icon: NotificationOutlined,
        name: 'Hub de Notificação',
        path: '/notification-hub',
      },
    },
    {
      name: 'Wallet',
      basename: 'wallet',
      url: 'https://microfrontendhml.blob.core.windows.net/wallet/Tradeback-wallet.js',
      sideBar: {
        icon: FolderOpenOutlined,
        name: 'Wallet',
        path: '/wallet',
      },
    },
    // {
    //   name: 'Cupom Fiscal',
    //   basename: 'receipt',
    //   url: 'https://microfrontendhml.blob.core.windows.net/receipt/Tradeback-receipt.js',
    //   sideBar: {
    //     icon: ProfileOutlined,
    //     name: 'Cupom Fiscal',
    //     path: '/receipt',
    //   },
    // },
    {
      name: 'Participante',
      basename: 'participant',
      url: 'https://microfrontendhml.blob.core.windows.net/participant/Tradeback-participant.js',
      sideBar: {
        icon: UserOutlined,
        name: 'Participante',
        path: '/participant',
      },
    },
    {
      name: 'Código Promocional',
      basename: 'promotional-code',
      url: 'https://microfrontendhml.blob.core.windows.net/promotional-code/Tradeback-promotional-code.js',
      sideBar: {
        icon: BarcodeOutlined,
        name: 'Código Promocional',
        path: '/promotional-code',
      },
    },
    // {
    //   name: 'Campanha',
    //   basename: 'campaign',
    //   url: 'https://microfrontendhml.blob.core.windows.net/campaign/Tradeback-campaign.js',
    //   sideBar: {
    //     icon: ClusterOutlined,
    //     name: 'Campanha',
    //     path: '/campaign',
    //   },
    // },
    // {
    //   name: 'Produto',
    //   basename: 'product',
    //   url: 'https://microfrontendhml.blob.core.windows.net/product/Tradeback-product.js',
    //   sideBar: {
    //     icon: ShoppingCartOutlined,
    //     name: 'Produto',
    //     path: '/product',
    //   },
    // },
    // {
    //   name: 'Ofertas',
    //   basename: 'sale',
    //   url: '',
    //   sideBar: {
    //     icon: DollarCircleOutlined,
    //     name: 'Ofertas',
    //     path: '/sale',
    //   },
    // },
    // {
    //   name: 'Horário da Sorte',
    //   basename: 'luck-times',
    //   url: '',
    //   sideBar: {
    //     icon: ScheduleOutlined,
    //     name: 'Horário da Sorte',
    //     path: '/luck-times',
    //   name: 'Bin de cartão',
    //   basename: 'card-bin',
    //   url: '',
    //   sideBar: {
    //     icon: CreditCardOutlined,
    //     name: 'Bin de cartão',
    //     path: '/card-bin',
    //   },
    // },{
    //   name: 'Limite de Participação',
    //   basename: 'participation-limit',
    //   url: '',
    //   sideBar: {
    //     icon: UserSwitchOutlined,
    //     name: 'Limite de Participação',
    //     path: '/participation-limit',
    //   },
    // },{
    //   name: 'Empresas',
    //   basename: 'company',
    //   url: '',
    //   sideBar: {
    //     icon: TrademarkOutlined,
    //     name: 'Empresas',
    //     path: '/company',
    //   },
    // },{
    //   name: 'Palavras Proibidas',
    //   basename: 'forbidden-words',
    //   url: '',
    //   sideBar: {
    //     icon: FileTextOutlined,
    //     name: 'Palavras Proibidas',
    //     path: '/forbidden-words',
    //   },
    // },
    {
      name: 'Parceiros',
      basename: 'partner',
      url: 'https://microfrontendhml.blob.core.windows.net/partner/Tradeback-partner.js',
      sideBar: {
        icon: TeamOutlined,
        name: 'Parceiros',
        path: '/partner',
      },
    },
  ],
  managerApi: {
    baseUrl: 'https://manager-hml.aberto.com.br',
    subscriptionKey: '0191041c191d4415acdddbfd6d572eaa',
    authentication: {
      clientId: '5a5b7996-168a-4860-bf20-67f591e42059',
      authorityDomain: 'abertomanagerhml.b2clogin.com',
      authorities: {
        signUpSignIn: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_SIGNIN' },
        forgotPassword: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_PASSWORDRESET' },
        editProfile: { authority: 'https://abertomanagerhml.b2clogin.com/abertomanagerhml.onmicrosoft.com/B2C_1A_PROFILEEDIT' },
      },
      scopes: {
        login: ['https://abertomanagerhml.onmicrosoft.com/manager-api/access_as_user'],
        token: ['https://abertomanagerhml.onmicrosoft.com/manager-api/access_as_user'],
      },
    },
  },
}

export default externalConfig
