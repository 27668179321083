import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleBenefitEffects'

export const REQUEST_GET = 'SaleBenefit.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleBenefit.REQUEST_GET_FINISHED'
export const REQUEST_UPDATE = 'SaleBenefit.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'SaleBenefit.REQUEST_UPDATE_FINISHED'
export const SET_BENEFIT = 'SaleBenefit.SET_BENEFIT'
export const REMOVE_BENEFIT = 'SaleBenefit.REMOVE_BENEFIT'
export const SET_SELECTED_ACCELERATOR = 'SaleBenefit.SET_SELECTED_ACCELERATOR'
export const REMOVE_ACCELERATOR = 'SaleBenefit.REMOVE_ACCELERATOR'
export const EDIT_ACCELERATOR = 'SaleBenefit.EDIT_ACCELERATOR'
export const SET_ACCELERATOR = 'SaleBenefit.SET_ACCELERATOR'
export const SET_ACCELERATOR_IDENTIFIER = 'SaleBenefit.SET_ACCELERATOR_IDENTIFIER'
export const REMOVE_ACCELERATOR_IDENTIFIER = 'SaleBenefit.REMOVE_ACCELERATOR_IDENTIFIER'
export const CLEAN = 'SaleBenefit.CLEAN'

export const SET_PARTICIPATION_AUTHORIZATION = 'SaleBenefit.SET_PARTICIPATION_AUTHORIZATION'
export const SET_PARTICIPATION_AUTHORIZATION_FINISHED = 'SaleBenefit.SET_PARTICIPATION_AUTHORIZATION_FINISHED'
export const REQUEST_GET_LIST = 'SaleBenefit.REQUEST_GET_LIST'
export const REQUEST_GET_LIST_FINISHED = 'SaleBenefit.REQUEST_GET_LIST_FINISHED'
export const REMOVE_GRANT_PARTICIPATION_AUTHORIZATION_DETAILS = 'SaleBenefit.REMOVE_GRANT_PARTICIPATION_AUTHORIZATION_DETAILS'

export function get(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
  }
}

export function getBasicInfoBySaleIds(salesId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_LIST, Effects.getBasicInfoBySaleIds, salesId)
  }
}

export function setSaleInfo(saleInfo) {
  return ActionUtility.createAction(SET_PARTICIPATION_AUTHORIZATION, saleInfo)
}

export function update(saleId) {
  return async (dispatch, getState) => {
    let {
      benefits: {
        accelerators,
        products,
        discount,
        voucher,
        thirdPartyVoucher,
        credit,
        pinCode,
        productFinalPrice,
        creditRedemption,
        grantParticipationAuthorization,
        luckyNumbers,
        voucherHub,
      },
    } = getState().sale

    accelerators = accelerators != null ? accelerators : []
    products = products != null && products.length > 0 ? products : []
    discount = discount != null ? discount : undefined
    voucher = voucher != null ? voucher : undefined
    thirdPartyVoucher = thirdPartyVoucher != null ? thirdPartyVoucher : undefined
    credit = credit != null ? credit : undefined
    pinCode = pinCode != null ? pinCode : undefined
    productFinalPrice = productFinalPrice != null ? productFinalPrice : undefined
    creditRedemption = creditRedemption != null ? creditRedemption : undefined
    grantParticipationAuthorization = grantParticipationAuthorization != null ? grantParticipationAuthorization : undefined
    luckyNumbers = luckyNumbers != null ? luckyNumbers : undefined
    voucherHub = voucherHub != null ? voucherHub : undefined

    const data = {
      accelerators,
      products,
      discount,
      voucher,
      thirdPartyVoucher,
      credit,
      pinCode,
      productFinalPrice,
      creditRedemption,
      grantParticipationAuthorization,
      luckyNumbers,
      voucherHub,
    }
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          description: null,
          message: 'Benefícios atualizados com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}

export function clean() {
  return ActionUtility.createAction(CLEAN)
}

export function removeGrantParticipationAuthorizationDetails() {
  return ActionUtility.createAction(REMOVE_GRANT_PARTICIPATION_AUTHORIZATION_DETAILS)
}

export function setBenefit({ type }, value) {
  return ActionUtility.createAction(SET_BENEFIT, { type, value })
}

export function setAcceleratorIdentifier(identifier) {
  return ActionUtility.createAction(SET_ACCELERATOR_IDENTIFIER, identifier)
}

export function removeAcceleratorIdentifier(index) {
  return ActionUtility.createAction(REMOVE_ACCELERATOR_IDENTIFIER, index)
}

export function setSelectedAccelerator(identifier) {
  return ActionUtility.createAction(SET_SELECTED_ACCELERATOR, identifier)
}

export function setAccelerator(accelerator) {
  return ActionUtility.createAction(SET_ACCELERATOR, accelerator)
}

export function removeAccelerator(index) {
  return ActionUtility.createAction(REMOVE_ACCELERATOR, index)
}

export function editAccelerator(index) {
  return ActionUtility.createAction(EDIT_ACCELERATOR, index)
}

export function removeBenefit({ type }) {
  return ActionUtility.createAction(REMOVE_BENEFIT, { type })
}
