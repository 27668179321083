import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import { SaleStatus } from '../../stores/sale/models/SaleEnums'
import { dateConfig } from '../../utilities/DateUtility'

const _searchResult = search => {
  const {
    response: { numberOfRows, data },
    pagination: { pageSize, pageNumber },
  } = search

  return {
    pagination: {
      pageSize,
      current: pageNumber,
      total: numberOfRows,
    },
    data: data.map(({ id, name, initialDate, finalDate, status, campaign, promoter }) => {
      return {
        id: id,
        name: name,
        initialDate: initialDate ? moment(initialDate).format(dateConfig.displayFormat) : null,
        finalDate: finalDate ? moment(finalDate).format(dateConfig.displayFormat) : null,
        status: status,
        statusText: SaleStatus[status],
        campaign: campaign?.name,
        promoter: promoter?.name,
      }
    }),
  }
}

const _pagination = pagination => pagination
const _identificationInfo = identificationInfo => identificationInfo

const _filter = filter => {
  let { initialPeriodDate, finalPeriodDate, ...rest } = filter

  const extractDate = date => (date ? moment(date, dateConfig.dateFormat) : undefined)

  return {
    initialPeriodDate: [extractDate(initialPeriodDate?.start), extractDate(initialPeriodDate?.end)],
    finalPeriodDate: [extractDate(finalPeriodDate?.start), extractDate(finalPeriodDate?.end)],
    ...rest,
  }
}

export const searchResult = createSelector(state => state.sale.search, _searchResult)
export const filter = createSelector(state => state.sale.search.filter, _filter)
export const pagination = createSelector(state => state.sale.search.pagination, _pagination)
export const identificationInfo = createSelector(state => state.sale.search.identificationInfo, _identificationInfo)
